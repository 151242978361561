export const addEventListener = (function () {
      if (document.addEventListener) {
        return function (element, event, handler) {
          element.addEventListener(event, handler, false);
        };
      } else {
        return function (element, event, handler) {
          element.attachEvent('on' + event, handler);
        };
    }
  })();