import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Icon from '../Icon';
import { addEventListener } from '../../utils/general';
import Swal from 'sweetalert2';
import './StudyItemStyle.css'

const baseClasses =
  'first:border-0 border-t border-secondary-light cursor-pointer select-none outline-none';

const StudyItem = ({
  date,
  description,
  numInstances,
  modalities,
  trackedSeries,
  isActive,
  onClick,
  studyInstanceUid,
}) => {
  const [isAdmin, setIsAdmin] = useState(false);
  const sentMessage = (message:{event?: String, source:String, data: String})=>  window.parent.postMessage(message, "*")
  useEffect(()=>{
    addEventListener(window, 'message', (message:{origin:String, data:{source:String, allow: true}})=>{
      if(message.data?.source === "pcap" && message.data?.allow === true ){
        setIsAdmin(true)
      }
      else if(message.data?.source === "pcap" && !message.data?.allow){
        setIsAdmin(false)
      }
    });
    window.setTimeout(()=>{
      sentMessage({source: "ohif", data: "loaded"})
    },2000)
  },[])
  const deleteStudy = (e:Event)=>{
    e.stopPropagation();
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'rgb(221, 107, 85)',
      cancelButtonColor: '#d0d0d0',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then(result => {
      if (result.isConfirmed) {
        sentMessage({source:"ohif" ,event: "delete", data:studyInstanceUid})
      }
    });
  }
  return (
    <div
      className={classnames(
        isActive ? 'bg-secondary-dark' : 'bg-black hover:bg-secondary-main',
        baseClasses
      )}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex="0"
    >
      <div className="flex flex-col flex-1 px-4 pb-2">
        <div className="flex flex-row items-center justify-between pt-2 pb-2">
          <div className="text-base text-white">{date}</div>
          <div className="flex flex-row items-center text-base text-blue-300">
            <Icon name="group-layers" className="w-4 mx-2 text-blue-300" />
            {numInstances}
          </div>
          {isAdmin &&
          <div className="flex flex-row items-right">
          <button className='del-btn' title='Delete Study' onClick={deleteStudy}><i className="fa fa-trash-o del-icon" ></i></button>
          </div>}
        </div>
        <div className="flex flex-row py-1">
          <div className="pr-5 text-xl text-blue-300">{modalities}</div>
          <div className="text-base text-blue-300 break-words truncate-2-lines">
            {description}
          </div>
        </div>
      </div>
      {!!trackedSeries && (
        <div className="flex flex-2">
          <div
            className={classnames(
              'flex flex-row bg-secondary-main text-base text-white py-1 pl-2 pr-4 mt-2 ',
              isActive
                ? 'flex-1 border-t border-secondary-light justify-center'
                : 'rounded-sm mx-4 mb-4'
            )}
          >
            <Icon name="tracked" className="w-4 mr-2 text-primary-light" />
            {trackedSeries} Tracked Series
          </div>
        </div>
      )}
    </div>
  );
};

StudyItem.propTypes = {
  date: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  modalities: PropTypes.string.isRequired,
  numInstances: PropTypes.number.isRequired,
  trackedSeries: PropTypes.number,
  isActive: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default StudyItem;
